import {defineStore} from 'pinia';
import {computed, inject, ref} from 'vue';
import {deserialize} from '@sebbia/object-deserializer';
import {DefaultApolloClient, provideApolloClient, useMutation, useQuery} from '@vue/apollo-composable';
import {RESTORE_PASSWORD, SIMPLE_USER_REGISTRATION, UPDATE_PROFILE} from "@/graphql/mutations";
import {GET_PROFILE} from "@/graphql/queries";
import {userDeserializer} from "@/graphql/deserializers";
import {message} from "ant-design-vue";
import {flatUserDTO, formattedTimeToResend, sanitizePhone} from "@/utils/formatters";

export const useUserStore = defineStore('userStore', () => {
  const apolloClient = inject(DefaultApolloClient)

  const code = ref('');
  const name = ref('');
  const surname = ref('');
  const email = ref('');
  const phone = ref('');
  const userId = ref('');
  const isFormValid = ref(false);
  const nextRestoreAfterMsecs = ref(0);
  const sberSoundChecked = ref(true);

  startTimerToResendMessage();

  // TODO: Remove if not needed in future
  function tryToRestartTimerFromLocalStorage() {
    const passResetTime = getResendTimeFromLocalStorage();
    if (passResetTime && passResetTime > 0) {
      nextRestoreAfterMsecs.value = getResendTimeFromLocalStorage();
      startTimerToResendMessage();
    }
  }

  function getResendTimeFromLocalStorage() {
    const resendTime = localStorage.getItem('nextRestore');
    if (resendTime) {
      const currentTime = new Date();
      const storedTime = new Date(parseInt(resendTime));

      const timeDiff = storedTime.getTime() - currentTime.getTime();
      (timeDiff < 0) && clearResendTimeFromLocalStorage();
      return timeDiff;
    }
  }

  function setResendTimeToLocalStorage(nextRestoreAfterMsecs) {
    const now = new Date();
    localStorage.setItem('nextRestore', (now.getTime() + nextRestoreAfterMsecs).toString());
  }

  function clearResendTimeFromLocalStorage() {
    localStorage.removeItem('nextRestore');
  }


  function startTimerToResendMessage() {
    // Preventing creating a new timer every time after page refresh
    if (nextRestoreAfterMsecs.value <= 0 && getResendTimeFromLocalStorage() > 0) {
      return;
    }

    let intervalId = setInterval(() => {
      if (nextRestoreAfterMsecs.value > 0) {
        nextRestoreAfterMsecs.value -= 1000;
        // setResendTimeToLocalStorage();
      } else {
        nextRestoreAfterMsecs.value = 0;
        clearInterval(intervalId);
        clearResendTimeFromLocalStorage();
      }
    }, 1000);
  }

  const restorePassword = async (phone, captchaCode = undefined) => {
    const captchaVar = captchaCode ? {captcha: captchaCode} : null;
    const {mutate: restore} = provideApolloClient(apolloClient)(() => useMutation(RESTORE_PASSWORD));
    const {data} = await restore({phone, ...captchaVar});
    const deserializedData = deserialize(data, o => o.required('registration.restorePassword.nextRestoreAfterMsecs').asNumber)
    nextRestoreAfterMsecs.value = deserializedData;
    setResendTimeToLocalStorage(deserializedData);
    startTimerToResendMessage();
  };

  async function resendMessage(phone, onSuccess, onError) {
    const formattedPhone = sanitizePhone(phone);
    const tooFrequentlyErrorCode = '19290a30';
    await restorePassword(formattedPhone)
      .then(() => onSuccess())
      .catch((e) => {
        const eUid = e.graphQLErrors[0].extensions.uid || '';
        if (eUid === tooFrequentlyErrorCode) {
          const timeToResend = formattedTimeToResend(nextRestoreAfterMsecs.value || getResendTimeFromLocalStorage() || 0);
          message.error(`Вы слишком часто запрашивали проверочный код, повторите через ${timeToResend}`)
        }
        onError(e);
      })
  }

  const registration = async (phone, captchaCode = undefined, onSuccess, onError) => {
    const {
      mutate: register
    } = provideApolloClient(apolloClient)(() => useMutation(SIMPLE_USER_REGISTRATION));

    const userAlreadyRegisteredCode = '728ea3ad';
    const tooFrequentlyErrorCode = '19290a30';

    const formattedPhone = sanitizePhone(phone);
    return await register({phone: formattedPhone, captcha: captchaCode})
      .then(() => onSuccess())
      .catch((error) => {
        const errorUid = error.graphQLErrors[0].extensions.uid || '';
        if (errorUid === userAlreadyRegisteredCode) {
          restorePassword(formattedPhone, captchaCode)
            .then(() => onSuccess())
            .catch((e) => {
              const eUid = e.graphQLErrors[0].extensions.uid || '';
              if (eUid === tooFrequentlyErrorCode) {
                const timeToResend = formattedTimeToResend(nextRestoreAfterMsecs.value || getResendTimeFromLocalStorage() || 0);
                message.error(`Вы слишком часто запрашивали проверочный код, повторите через ${timeToResend}`)
              }
              onError(e);
            })
        } else {
          onError(error);
          console.error(error);
        }
      });
  };

  const updateProfile = async () => {
    const {
      mutate: updateProfile,
    } = provideApolloClient(apolloClient)(() => useMutation(UPDATE_PROFILE));
    const personDto = {
      name: {'ru': name.value},
      surname: {'ru': surname.value},
      contacts: [
        {
          type: 'PHONE',
          value: sanitizePhone(phone.value)
        },
        {
          type: 'EMAIL',
          value: email.value
        }
      ],
    };

    try {
      const response = await updateProfile({person: personDto});
      const id = deserialize(response.data, o => o.required('users.updateProfile.id').asString);
      return response;
    } catch (error) {
      console.error('updateProfile error: ', error);
      throw error;
    }
  };

  const userData = computed(() => {
    return {
      id: userId.value,
      name: name.value,
      surname: surname.value,
      phone: phone.value,
      email: email.value,
    };
  })

  const setUserData = (userData) => {
    name.value = userData?.name;
    surname.value = userData?.surname;
    email.value = userData?.email;
    phone.value = userData?.phone;
    userId.value = userData?.id;
  };

  const fetchUser = async (onSuccess, onFail = undefined) => {
    const {onError, onResult} = provideApolloClient(apolloClient)(() => useQuery(GET_PROFILE));
    onResult((res) => {
      if (res && res.data) {
        const userDeserialized = deserialize(res.data, o =>
          o.required('users.getProfile').asObject(o => userDeserializer(o))
        );
        const userData = flatUserDTO(userDeserialized);
        setUserData(userData);
        onSuccess(userData);
      }
    })
    onError((e) => {
      console.error('on fail user fetch: ', e);
      !!onFail && onFail(e);
    })
  };

  const resetUserStore = () => {
    name.value = '';
    surname.value = '';
    email.value = '';
    phone.value = '';
    userId.value = '';
    isFormValid.value = false;
    nextRestoreAfterMsecs.value = 0;
  }

  return {
    code,
    name,
    surname,
    email,
    phone,
    userData,
    isFormValid,
    nextRestoreAfterMsecs,
    sberSoundChecked,
    resendMessage,
    resetUserStore,
    restorePassword,
    registration,
    updateProfile,
    fetchUser,
  };
});
