<template>
  <loader v-if="pricesStore.loading"></loader>
  <div v-else class="choose">
    <div class="choose__header">
      <h2>
        {{ fullDateFormatter(orderStore.currentSession.startTime, ['day', 'month', 'hour', 'minute']) }}
      </h2>
      <a-checkbox
        v-if="isSpecialNotice"
        v-model:checked="userStore.sberSoundChecked"
        class="desktop-checkbox"
      >
        Допуск на фестиваль при наличии активной подписки Прайм в приложении <a href=" https://open.zvuk.com/cLQ0/kfxuxomd" alt="Звук" target="_blank">Звук</a>
      </a-checkbox>
    </div>
    <div class="choose__main" :class="orderStore.currentSession.features.length ? 'choose__main_special' : ''">
      <div v-if="orderStore.currentSession.features.length" class="choose__banner">
        <img
          class="choose__banner__desktop"
          :src="orderStore.currentSession.features[0]?.banner?.publicLink || ''"
          :alt="orderStore.currentSession.features[0]?.description['en'] || ''"
        >
        <img
          class="choose__banner__mobile"
          :src="orderStore.currentSession.features[1]?.banner?.publicLink || ''"
          :alt="orderStore.currentSession.features[1]?.description['en'] || ''"
        >
      </div>
      <div class="choose__tickets custom-scrollbar">
        <div class="choose__ticket" v-for="price of pricesStore.prices">
          <div class="choose__content">
            <div class="ticket__header">
              <div class="ticket__info">
                <div class="ticket__title">{{ price.priceCategory.title }}</div>
                <div class="ticket__price">{{ price.value.toString() }}₽</div>
              </div>
              <div class="ticket__counter">
                <div class="counter">
                  <button
                    :disabled="orderStore.getAmountByCategoryId(price.priceCategory.id) === 0"
                    class="counter__button disable-dbl-tap-zoom"
                    @click="minusButtonClickHandler(price)"
                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="var(--color-secondary)"/>
                      <rect x="24" y="14" width="4" height="16" rx="2" transform="rotate(90 24 14)" fill="white"/>
                    </svg>
                  </button>
                  <div class="counter__value">{{ orderStore.getAmountByCategoryId(price.priceCategory.id) }}</div>
                  <button
                    :disabled="orderStore.getTotalQuantity === salesLimit"
                    class="counter__button disable-dbl-tap-zoom"
                    @click="plusButtonClickHandler(price)"
                  >
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="16" cy="16" r="16" fill="var(--color-secondary)"/>
                      <rect x="14" y="8" width="4" height="16" rx="2" fill="white"/>
                      <rect x="24" y="14" width="4" height="16" rx="2" transform="rotate(90 24 14)" fill="white"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <div class="ticket__description" v-if="priceCategoryDescriptions.get(price.priceCategory.title)">
              {{ priceCategoryDescriptions.get(price.priceCategory.title) }}
            </div>
          </div>
        </div>
      </div>
      <a-checkbox
        v-if="isSpecialNotice"
        v-model:checked="userStore.sberSoundChecked"
        class="mobile-checkbox"
      >
        Допуск на фестиваль при наличии активной подписки Прайм в приложении <a href=" https://open.zvuk.com/cLQ0/kfxuxomd" alt="Звук" target="_blank">Звук</a>
      </a-checkbox>
    </div>
  </div>
</template>

<script setup>
import {usePricesStore} from "@/store/PricesStore";
import {useOrderStore} from "@/store/OrderStore";
import { priceCategoryDescriptions} from "@/constants";
import Loader from "@/components/Loader.vue";
import {fullDateFormatter} from "@/utils/formatters";
import {useUserStore} from "@/store/UserStore";
import {onMounted, onUnmounted, ref} from "vue";

const salesLimit = +window.parametrize('VUE_APP_SALES_LIMIT', 10);

const pricesStore = usePricesStore();
const orderStore = useOrderStore();
const userStore = useUserStore();

const isSpecialNotice = ref(false);

onMounted(() => {
  const startTime = new Date(orderStore.currentSession.startTime);
  //  сеанс 2.12.2023-20:00
  if (startTime.getFullYear() === 2023 && startTime.getDate() === 2 && startTime.getMonth() === 11 && startTime.getTime() === 1701536400000) {
    isSpecialNotice.value = true;
    userStore.sberSoundChecked = false;
  }
})

onUnmounted(() => {
  if (isSpecialNotice.value) {
    isSpecialNotice.value = false;
    userStore.sberSoundChecked = true;
  }
})

const plusButtonClickHandler = (price) => {
  orderStore.increaseAmountByCategoryId(price.priceCategory.id)
  pricesStore.increaseTotalPrices(price.value)
};
const minusButtonClickHandler = (price) => {
  orderStore.decreaseAmountByCategoryId(price.priceCategory.id)
  pricesStore.decreaseTotalPrices(price.value)
};

</script>

<style lang="stylus">
.desktop-checkbox a {
  color: var(--color-secondary) !important;
}
.mobile-checkbox a {
  color: var(--color-secondary) !important;
}
.desktop-checkbox a:hover {
  color: var(--color-primary) !important;
}
.mobile-checkbox a:hover {
  color: var(--color-primary) !important;
}

.choose__tickets {
  width: 100%;
}

.choose {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    color: var(--color-dark);

    & h2{
      text-align: left;
      font-family: var(--font-family);
      font-size: var(--fz-24);
      font-weight: var(--font-400);
      display: inline-block;
    }
    & span {
      line-height: 1.2;
    }
  }

  &__banner {
    position: sticky;
  }

  &__main {
    display: flex;
  }

  &__main_special {
    gap: 40px;

    .choose__banner__mobile {
      display: none;
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 28px;

      .choose__banner {
        align-self: center;
      }
    }

    @media (max-width: 480px) {
      .choose__banner__desktop {
        display: none;
      }

      .choose__banner__mobile {
        display: block;
      }
    }
  }

  &__tickets {
    overflow: auto;
    max-height: 406px;
  }

  &__ticket {
    padding: 25px 20px 25px 0px;
    border-bottom: 1px solid var(--color-beige);

    &:first-child {
      padding: 0px 20px 25px 0px;
    }
  }
}

.ticket {
  &__header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
  }

  &__title {
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: var(--fz-24);
    font-weight: var(--font-500);
  }

  &__price {
    color: $darkColor;
    font-family: IBM Plex Sans;
    font-size: var(--fz-16);
    font-weight: var(--font-400);
  }

  &__counter {
    font-size: var(--fz-32);
    font-weight: var(--font-500);
  }

  &__description {
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: var(--fz-14);
    font-weight: var(--font-400);
    display: inline-block;
    margin-top: 24px;
    max-width: 480px;
  }
}

.counter {
  width: 148px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.counter__button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.desktop-checkbox span {
  max-width: 390px;
}

.mobile-checkbox {
  display: none !important;
}

.mobile-checkbox span {
  line-height: 1.2;
}

@media (max-width: 768px) {
  .desktop-checkbox {
    display: none !important;
  }

  .mobile-checkbox {
    display: flex !important;
  }

  .counter {
    width: 118px;
  }
}
</style>
