import gql from 'graphql-tag';
import {ORDER_FRAGMENT_FIELDS} from "@/graphql/fragments";

// TODO: Replace getList with getAvailableList when it's ready
export const GET_AVAILABLE_EVENT_LIST = gql`
  query getAvailableEventList($filter: EventsFilter, $pagination: Pagination) {
    eventLocalised {
      getAvailableList(filter: $filter, pagination: $pagination) {
        list {
          id
          startTime
          endTime
          hidden
          status
          features {
            description
            banner {
              id
              fileName
              publicLink
            }
          }
        }
      }
    }
  },
`

export const GET_PROFILE = gql`
  query getProfile {
    users {
      getProfile {
        id
        person {
          name
          surname
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;

export const GET_PRICE_CATEGORY_BY_EVENT = gql`
  query getPriceCategoryByEvent($eventId: ID!) {
    priceTemplate {
      getByEvent(eventId: $eventId) {
        template {
          prices {
            value
            priceCategory {
              id
              title
            }
          }
        }
      }
    }
  }
`
export const GET_CURRENT_ONLY = gql`
  ${ORDER_FRAGMENT_FIELDS}
  query getCurrentOnly {
    order {
      getCurrentOnly {
        ...OrderFields
      }
    }
  }
`
