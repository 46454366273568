<template>
  <div class="payment-methods">
    <div class="forms">
      <a-form v-if="formStep == 1" ref="formRef" :model="formState" :rules="rules">
        <div class="form__field">
          <div class="payment-methods__title"><span>Номер телефона</span></div>
          <a-form-item ref="tel" name="tel">
            <a-input v-model:value="formState.tel" placeholder="+ 7 ___ - __ - __" v-mask="'+ 7 (###) ###-##-##'"
                     @keyup.enter="onSubmitTel"/>
          </a-form-item>
          <div class="sub-info">
            Нажимая кнопку “Подтвердить”, вы даете согласие <br/>
            на обработку персональных данных. <br/>
            <a v-if="userAgreementLink" :href="userAgreementLink" target="_blank" class="sub-info__link">Пользовательское
              соглашение</a>
          </div>
        </div>
        <a-button type="primary" class="form__submit_role_tel primary__border" :loading="loading" @click="onSubmitTel">
          Подтвердить
        </a-button>
      </a-form>
      <a-form v-if="formStep == 2" ref="formRef" :model="formState" :rules="rules">
        <div class="form__field">
          <div class="payment-methods__title"><span>Код из СМС</span></div>
          <a-form-item ref="code" name="code">
            <a-input
              type="tel"
              name="password"
              autocomplete="one-time-code"
              v-model:value="formState.code"
              placeholder="00000"
              v-mask="'#####'"
              @input="onSubmitCode"
              autofocus
            />
          </a-form-item>
          <div v-if="userStore.nextRestoreAfterMsecs > 0" class="sub-info sub-info_position_center sub-info__small">
            Для повторной отправки {{ formattedTimeToResend(userStore.nextRestoreAfterMsecs) }}
          </div>
          <div v-else class="sub-info sub-info_position_center sub-info__small">
            Не пришел код ? <br/>
            Отправить<a v-on:click.prevent="repeatMessage($event)"> повторно SMS</a>
          </div>
        </div>
      </a-form>
      <a-form
        v-if="formStep === 3"
        ref="formRef"
        :model="formState"
        :rules="rules"
        @validate="onValidate"
      >
        <div class="form__field">
          <div class="payment-methods__title"><span>Номер телефона</span></div>
          <a-form-item
            ref="tel"
            name="tel"
          >
            <a-input
              disabled
              v-model:value="formState.tel"
              placeholder="+ 7 ___ - __ - __"
              v-mask="'+ 7 (###) ###-##-##'"
              data-id="input-tel"
            />
          </a-form-item>
        </div>
        <div class="form__field">
          <div class="payment-methods__title"><span>Введите ваш e-mail</span></div>
          <a-form-item
            ref="email"
            name="email"
            :validate-trigger="validateState['email'].mode"
            :validate-status="validateState['email'].status"
          >
            <a-input
              v-model:value="formState.email"
              type="email"
              placeholder="введите ваш e-mail"
              @blur="handleBlur('email')"
              autofocus
            />
          </a-form-item>
        </div>
        <div class="form__field">
          <div class="payment-methods__title"><span>Имя и фамилия</span></div>
          <a-form-item
            ref="name"
            name="name"
            :validate-trigger="validateState['name'].mode"
            :validate-status="validateState['name'].status"
          >
            <a-input
              v-model:value="formState.name"
              placeholder="введите ваше имя и фамилию"
              @blur="handleBlur('name')"
              @change="handleUserNameChange"
            />
          </a-form-item>
        </div>
      </a-form>
    </div>
    <div class="payment-methods__wrapper">
      <div v-if="!userStore.isFormValid" class="hide-block"></div>
      <div class="payment-methods__header">
        <h2 class="payment-methods__title">Способ оплаты</h2>
      </div>
      <div class="payment-methods__info">
        <div class="payment-methods__text">
          <div class="payment-methods__discount">Получите скидку {{ pricesStore.getDiscountAmount }} ₽</div>
          <div class="payment-methods__description">при оплате картой Сбера</div>
        </div>
      </div>
      <div class="payment-methods__items">
        <a-radio-group v-model:value="orderStore.paymentMethod" @change="onPaymentMethodChange">
          <a-radio-button :value="PaymentsAdapters.SBER">
            <a-badge
              :count="getSberDiscount()"
              :number-style="{
                padding: '10px',
                fontSize: '11px',
                height: 'auto',
                lineHeight: '1',
                background: 'linear-gradient(77deg, #0FA8E0 19.25%, #0099F9 54.45%, #0291EB 82.72%)',
                color: '#fff',
                boxShadow: 'none',
                cursor: 'default',
                transform: 'translate(70%, -120%)',
                borderRadius: '30px',
              }"
            >
              <div class="payment-methods__item">
                <div class="payment-methods__name">Картой</div>
                <div class="payment-methods__icon"><img src="@/assets/sberLogoWithText.svg" alt=""/></div>
              </div>
            </a-badge>
          </a-radio-button>
          <!--          TODO: восстановить, когда эти способы оплаты будут реализованы-->
          <!--          <a-radio-button :value="PaymentsAdapters.SBP">-->
          <!--            <div class="payment-methods__item">-->
          <!--              <div class="payment-methods__icon"><img src="@/assets/sbp.svg" alt="" /></div>-->
          <!--              <div class="payment-methods__name">СБП</div>-->
          <!--            </div>-->
          <!--          </a-radio-button>-->
          <!--          <a-radio-button :value="PaymentsAdapters.BONUSES">-->
          <!--            <div class="payment-methods__item">-->
          <!--              <div class="payment-methods__name">-->
          <!--                Бонусы<br />-->
          <!--                «Спасибо»-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </a-radio-button>-->
          <a-radio-button :value="PaymentsAdapters.OTHER">
            <div class="payment-methods__item">
              <div class="payment-methods__name">Карта другого <br/>банка</div>
            </div>
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, onUnmounted, reactive, ref, watch} from 'vue';
import {useUserStore} from '@/store/UserStore';
import {useTicketsStore} from '@/store/TicketsStore';
import {usePricesStore} from "@/store/PricesStore";
import {formattedTimeToResend, maskPhoneNumber, splitName} from "@/utils/formatters";
import {useAuthStore} from "@/store/AuthStore";
import {message} from "ant-design-vue";
import {useOrderStore} from "@/store/OrderStore";
import Loader from "@/components/Loader.vue";
import {PaymentsAdapters} from "@/utils/constants";

const userAgreementLink = window.parametrize('VUE_APP_USER_AGREEMENT');
const ticketsStore = useTicketsStore();
const userStore = useUserStore();
const authStore = useAuthStore();
const pricesStore = usePricesStore();
const orderStore = useOrderStore();
const loading = ref(false);
// const paymentMethod = ref('sber');
const formStep = ref(1);
const formRef = ref();
const formState = reactive({
  tel: '',
  code: '',
  email: '',
  name: '',
});

const validateState = reactive({
  name: {
    status: 'validating',
    mode: 'blur',
  },
  email: {
    status: 'validating',
    mode: 'blur',
  }
})

const handleBlur = (fieldName) => {
  if (validateState[fieldName].mode === 'blur') {
    validateState[fieldName].mode = ['change', 'blur'];
  }
}

const parseUserDataFromStore = async () => {
  formState.tel = maskPhoneNumber(userStore.phone) || '';
  formState.email = userStore.email || '';
  formState.name = `${userStore.name || ''}${userStore.surname ? ' ' + userStore.surname : ''}`;
  const phoneInput = document.querySelector(`[data-id="input-tel"]`);
  phoneInput && phoneInput.dispatchEvent(new Event('input', {bubbles: true}));
  !!(formState.tel && formState.name && formState.email) && formRef.value.validate().then(() => {
    Object.entries(validateState).forEach(([key, value]) => {
      if (value.status !== 'success') {
        validateState[key].status = 'success';
      }
    })
    userStore.isFormValid = true;
  });
};

const handleAuthStatus = async (authStatus) => {
  if (authStatus) {
    const onUserFetchFail = async (e) => {
      const badCredentialsError = '1fde2690';
      const errorUid = e.graphQLErrors[0]?.extensions?.uid || '';
      if (!!e && errorUid === badCredentialsError) {
        await authStore.logout();
        formStep.value = 1;
        userStore.resetUserStore();
      }
    }
    const onSuccess = async (userData) => {
      await parseUserDataFromStore(userData);
      formStep.value = 3;
    }
    await userStore.fetchUser(onSuccess, onUserFetchFail);
  } else if (userStore.nextRestoreAfterMsecs > 0) {
    formStep.value = 2;
  } else {
    formStep.value = 1;
    userStore.resetUserStore();
  }
}

const repeatMessage = (e) => {
  const onSuccess = () => {
    message.info('Сообщение отправлено повторно');
    loading.value = false;
  };
  const onError = (e) => {
    message.error('Произошла ошибка при повторной отправке');
    loading.value = false;
  }

  userStore.resendMessage(formState.tel, onSuccess, onError);
}

const handleUserNameChange = (e) => {
  if (authStore.authenticated) {
    userStore.name = splitName(e.target.value).name;
    userStore.surname = splitName(e.target.value).surname;
  }
};

watch(() => authStore.authenticated, async () => {
  await handleAuthStatus(authStore.authenticated)
})

const getSberDiscount = () => {
  return `- ${pricesStore.getDiscountAmount} ₽`;
};

const onPaymentMethodChange = (e) => {
  orderStore.setPaymentMethod(e.target.value);
  if (e.target.value === PaymentsAdapters.OTHER) {
    (dataLayer = window.dataLayer || []).push({
      'eCategory': 'dreams_cardAnotherBank',
      'eAction': 'clickButton',
      'event': 'GTMButtons'
    });
  } else if (e.target.value === PaymentsAdapters.SBERBANK) {
    (dataLayer = window.dataLayer || []).push({
      'eCategory': 'dreams_cardSber',
      'eAction': 'clickButton',
      'event': 'GTMButtons'
    });
  }
}

const onSubmitTel = () => {
  formRef.value
    .validate()
    .then(async () => {
      loading.value = true;
      const onSuccess = () => {
        loading.value = false;
        formStep.value += 1;
      };
      const onError = (e) => {
        loading.value = false;
      }
      await userStore.registration(formState.tel, undefined, onSuccess, onError);
    })
    .catch((error) => {
      console.log('error', error);
    });
};
const onSubmitCode = () => {
  formRef.value
    .validate()
    .then(async () => {
      await (authStore.login(formState.tel, formState.code)).catch((error) => {
        message.error('Введен неверный код');
        console.log('error', error);
      })
    })
    .catch((error) => {
      console.log('error', error);
    });
};

const onValidate = (name, status, errorMsgs) => {
  if (validateState.hasOwnProperty(name)) {
    validateState[name].status = status ? 'success' : 'error';
    let allFieldsValid = true;
    Object.entries(validateState).forEach(([key, value]) => {
      if (value.status !== 'success') {
        allFieldsValid = false;
      }
    });
    if (allFieldsValid) {
      onSubmitData();
    } else {
      userStore.isFormValid = false;
    }
  }
}

const onSubmitData = () => {
  userStore.phone = formState.tel;
  userStore.email = formState.email;

  userStore.name = splitName(formState.name).name;
  userStore.surname = splitName(formState.name).surname;
  userStore.isFormValid = true;
};

onMounted(async () => {
  orderStore.setPaymentMethod(PaymentsAdapters.SBER);
  await handleAuthStatus(authStore.authenticated);
})

onUnmounted(() => userStore.isFormValid = false);

const rules = {
  tel: [
    {
      min: 19,
      required: true,
      message: 'Введите корректный номер телефона',
      trigger: 'blur',
    },
  ],
  code: [
    {
      required: true,
      min: 5,
      message: '',
      trigger: 'change',
    },
  ],
  email: [
    {
      message: 'Введите корректный Email',
      required: true,
      type: 'email'
    },
  ],
  name: [
    {min: 5, message: 'Минимальная длина 5 символов'},
    {max: 35, message: 'Максимальная длина 35 символов'},
    {required: true, message: 'Введите имя и фамилию'},
    {pattern: new RegExp(/^[\p{L}-]+\s[\p{L}-]+$/u), message: 'Поле должно содержать имя и фамилию через пробел'},
  ],
};
</script>

<style lang="stylus">

.ant-radio-group.ant-radio-group-outline {
  display: flex;
  gap: 20px;
  width: 350px;
  flex-wrap: wrap;
}

.payment-methods {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.payment-methods__items .ant-radio-button-wrapper {
  height: 92px;
  max-height: 92px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1.5 !important;
}

.sub-info {
  margin-bottom: 10px;
}

.payment-methods__items .ant-radio-button-wrapper {
  border-inline-start-width: 1px;
  flex: 1 1 calc(50% - 16px);
  min-height: 80px;

  &:not(:first-child)::before {
    display: none;
  }
}

.payment-methods__item:first-child {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 14px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08), 0px 6px 12px -6px rgba(24, 39, 75, 0.12);
}

.sub-info {
  color: #A0A0A0;
  margin-top: 16px;
  font-family: var(--font-family);
  font-size: var(--fz-10);
  font-weight: var(--font-500);

  &_position {
    &_center {
      text-align: center;
    }
  }

  &__small {
    font-size: var(--fz-12);
    font-weight: var(--font-400);
  }
}

.form {
  &__field {
    .ant-input {
      padding: 10px 20px;
      color: var(--color-dark);
      font-family: var(--font-family);
      font-size: var(--fz-16);
      font-weight: var(--font-400);
      border-radius: 4px;
      outline: none;
      width: 292px;
      border: 1px solid var(--color-beige);
    }

    &:focus {
      border: 1px solid var(--color-secondary);
    }

    .ant-input-status-success {
      border: 1px solid green;
    }
  }

  &__fields {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.payment-methods {
  &__wrapper {
    position: relative;

    .hide-block {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background: var(--color-disabled);
      z-index: 10;
    }
  }

  &__info {
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    ine-height: 1.3;
    padding: 10px 12px;
    display: flex;
    gap: 16px;
    align-items: center;
    border-radius: 4px;
    background: var(--color-grey);
    margin-bottom: 16px;
  }

  &__title {
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: 24px;
    font-weight: var(--font-500);
    margin-bottom: 16px;
  }

  &__item {
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    flex: 0 0 50%;
  }


  &__name {
    text-align: center;
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: 14px;
    font-weight: var(--font-400);

    &_style {
      &_bold {
        color: var(--color-green);
        font-weight: 600;
      }
    }
  }
}
</style>
