<template>
  <loader v-if="eventsStore.loading"></loader>
  <a-tabs v-else v-model:activeKey="activeTabKey">
    <a-tab-pane v-for="month of eventsStore.months" :key="`${month}`" :tab="`${month.toLowerCase()}`">
      <div class="tab__panel custom-scrollbar">
        <div class="tab__item" v-for="day of eventsStore.sessions.find(m=> m.month === month).days">
          <div class="tab__day">
            <div class="tab__date">
              <span class="tab__number">{{ day.dayOfMonth }}</span>
            </div>
            <div class="tab__week">{{ day.sessions[0].weekday }}</div>
          </div>
          <div class="tab__times">
            <div v-for="session of day.sessions">
              <button v-if="session.status === 'COMPLETED'" class="tab__time tab__time_state_disable">{{
                  session.time
                }}
              </button>
              <a-badge
                v-else-if="!!session.features.length"
                count="Special"
                :number-style="{
                  padding: '0 5px',
                  fontSize: '11px',
                  backgroundColor: 'var(--color-secondary)',
                  color: '#fff',
                  boxShadow: 'none',
                  cursor: 'default',
                  transform: 'translate(0%, -50%)',
                }"
              >
                <button @click="$emit('next', session)" class="tab__time tab__time_type_special">
                  {{ session.time }}
                </button>
              </a-badge>
              <button v-else class="tab__time" @click="$emit('next', session)">{{ session.time }}</button>
            </div>
          </div>
        </div>
      </div>
    </a-tab-pane
    >
  </a-tabs>
</template>

<script setup>
import {useUserStore} from '@/store/UserStore';
import {useEventsStore} from '@/store/EventsStore';
import {h, onMounted, ref, watch} from 'vue';
import {LoadingOutlined} from '@ant-design/icons-vue';
import Loader from "@/components/Loader.vue";

const userStore = useUserStore();
const eventsStore = useEventsStore();
const props = defineProps(['activeKey']);

const activeTabKey = ref(props.activeKey || 0);

watch(() => props.activeKey, () => {
  activeTabKey.value = props.activeKey;
})

onMounted(() => {
  eventsStore.refetch();
})

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '60px',
  },
  spin: true,
});
</script>

<style lang="stylus">
.tab__time {
  color: var(--color-dark);
  font-family: var(--font-family);
  font-size: var(--fz-16);
  font-weight: var(--font-500);
  cursor: pointer;
  padding: 8px 16px;
  border: none;
  border-radius: 20px;
  transition: all 0.3s ease 0s;
  background: var(--color-beige);

  &_type {
    &_special {
      padding: 6px 16px 8px 16px;
      border: 2px solid var(--color-secondary);
      background: linear-gradient(180deg, var(--color-special) 43.23%, rgba(224, 224, 224, 0.00) 100%);
    }
  }

  &:hover {
    background: var(--color-primary);
  }

  &_state {
    &_disable {
      cursor: not-allowed;
      opacity: 0.5;

      &:hover {
        background: none;
      }
    }
  }
}

.tab__week {
  font-size: var(--fz-16);
}

.tab__item {
  display: flex;
  gap: 40px;
  padding: 24px 0px;
  align-items: center;
  border-bottom: 1px solid var(--color-beige);
}

.tab__date {
  display: flex;
  gap: 10px;
  width: 150px;
  font-size: var(--fz-24);
  font-style: normal;
  font-weight: var(--font-500);
  line-height: normal;
  white-space: nowrap;
}

.tab__day {
  align-self: flex-start;
}

@media (min-width: 768px) {
  .tab__panel {
    max-height: 412px;
  }
}

.tab__times {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tab__panel {
  overflow-y: auto;
}

.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}

.ant-tabs .ant-tabs-tab-btn {
  min-height: 36px;
  outline: none;
  transition: all 0.3s;
  padding: 8px 16px;
  border-radius: 20px;
  background: var(--color-beige);
  font-weight: var(--font-500);
  font-size: var(--fz-16);
  line-height: var(--fz-16);

  &:hover {
    background: var(--color-primary);
    color: var(--color-white);
  }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  background: var(--color-secondary);
  color: var(--color-white) !important;
  text-shadow: 0 0 0.25px currentcolor;
}

.tabs .ant-tabs-nav-list .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  background: var(--color-secondary);
  z-index: -1;
  border-radius: 20px;
  height: 36px;
  top: 50%;
  transform: translate(0%, -50%);
}
</style>
