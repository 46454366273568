import {defineStore} from 'pinia';
import {computed, h, inject, ref} from "vue";
import {DefaultApolloClient, provideApolloClient, useMutation, useQuery} from "@vue/apollo-composable";
import {CANCEL_ORDER, RESERVE_BY_CATEGORY} from "@/graphql/mutations";
import {GET_CURRENT_ONLY} from "@/graphql/queries";
import deserialize from "@/graphql/deserializers";
import {PaymentsAdapters} from "@/utils/constants";
import {Button, notification} from "ant-design-vue";
import {useEventsStore} from "@/store/EventsStore";

export const useOrderStore = defineStore('order', () => {
  const apolloClient = inject(DefaultApolloClient)
  const eventStore = useEventsStore();

  const currentSession = ref(null);
  const amountByCategory = ref(null);
  const totalQuantity = ref(0);
  const orderId = ref('');
  const paymentMethod = ref(PaymentsAdapters.OTHER);
  const currentOrder = ref(null);

  const {onResult, onError, refetch} = useQuery(GET_CURRENT_ONLY, null, {fetchPolicy: 'no-cache', enable: false});
  onResult((res) => {
    if (res && res.data) {
      const deserializedData = deserialize(res.data, 'order');
      currentOrder.value = deserializedData;
      if (deserializedData) {
        orderId.value = deserializedData.id;
      }
    }
  })
  onError((e) => {
    console.error(e);
  })

  const getAmountByCategory = computed(() => {
    return amountByCategory.value
  })

  const getAmountByCategoryId = computed(() => (categoryId) => {
    let currentAmountByCategory = 0;
    let currentCategory;
    if (amountByCategory.value && categoryId) {
      currentCategory = amountByCategory.value.find(item => item.categoryId === categoryId);
    }
    if (currentCategory) {
      currentAmountByCategory = currentCategory.amount
    }
    return currentAmountByCategory
  })

  const getTotalQuantity = computed(() => {
    return totalQuantity.value
  })

  function setCurrentSession(data) {
    currentSession.value = data
  }

  function setAmount(data) {
    amountByCategory.value = data;
  }

  function setPaymentMethod(data) {
    paymentMethod.value = data
  }

  function increaseAmountByCategoryId(categoryId) {
    const index = amountByCategory.value.findIndex(item => item.categoryId === categoryId)
    amountByCategory.value[index].amount += 1
    totalQuantity.value += 1
  }

  function decreaseAmountByCategoryId(categoryId) {
    const index = amountByCategory.value.findIndex(item => item.categoryId === categoryId)
    amountByCategory.value[index].amount -= 1
    totalQuantity.value -= 1
  }

  const reserveByCategory = async (onUnavailable) => {
    const {mutate: reserve} = provideApolloClient(apolloClient)(() => useMutation(RESERVE_BY_CATEGORY));
    try {
      const {data} = await reserve(
        {
          data: {
            eventId: currentSession.value.id,
            amountByCategory: amountByCategory.value.filter(item => item.amount > 0),
            tag: "ONLINE"
          }
        }
      );
      const deserializedData = deserialize(data, 'ticket')
      orderId.value = deserializedData[0].orderId;
      await getCurrentOnly();
    } catch (e) {
      const noLongerAvailableErrors = ['7d739e78', 'd7f581ce'];
      const errorUid = e.graphQLErrors[0]?.extensions?.uid || '';
      if (noLongerAvailableErrors.includes(errorUid)) {
        eventStore.disableEvent(currentSession.value.id); // [TEMP]
        const key = `open${Date.now()}`;
        notification.warning({
          duration: null,
          description:
            'Извините, но данный сеанс больше не доступен, но вы можете приобрести билет на другой сеанс',
          btn: () =>
            h(
              Button,
              {
                type: 'primary',
                size: 'small',
                onClick: () => {
                  notification.close(key);
                  onUnavailable();
                },
              },
              {default: () => 'К выбору сеансов'},
            ),
          key,
        })
        throw e;
      } else {
        console.error(e);
        throw e;
      }
    }
  }

  const getCurrentOnly = async () => {
    await refetch();
  }

  const cancelOrder = async () => {
    const {mutate: cancel} = provideApolloClient(apolloClient)(() => useMutation(CANCEL_ORDER));
    await cancel(
      {
        orderId: orderId.value
      }
    )
      .then(() => console.log(`Заказ с id=${orderId.value} удален!`))
      .catch((err) => console.error(`Ошибка при удалении заказа с id=${orderId.value}, ${err}`))
    currentOrder.value = null;
  }

  function orderReset() {
    currentSession.value = null;
    amountByCategory.value = null;
    totalQuantity.value = 0;
    orderId.value = '';
  }

  return {
    orderId,
    currentSession,
    amountByCategory,
    getAmountByCategory,
    getAmountByCategoryId,
    getTotalQuantity,
    paymentMethod,
    currentOrder,
    setCurrentSession,
    setAmount,
    setPaymentMethod,
    increaseAmountByCategoryId,
    decreaseAmountByCategoryId,
    reserveByCategory,
    getCurrentOnly,
    cancelOrder,
    orderReset,
  }
})
