import {formatDate} from '@/utils/formatters';

import {DeserializationError, deserialize as ds, ObjectDeserializer} from '@sebbia/object-deserializer';
import {Money} from "@/utils/Money";

function moneyMapper(value, path) {
  if (typeof value !== 'string') throw new DeserializationError(path, 'Not a money string');
  return Money.parse(value);
}

function ticketDeserializer(o) {
  return {
    priceCategory: o.required('priceCategory').asObject((d) => {
      return {
        id: d.required('id').asString,
        title: d.required('title').asString
      }
    })
  }
}

function orderItemDeserializer(o) {
  return o.required('item').asObject(ticketDeserializer)
}

function itemsDeserializer(o) {
  return {
    id: o.required('id').asString,
    item: orderItemDeserializer(o),
    itemQrCode: o.optional('itemQrCode').asString,
  }
}

export function orderDeserializer(o) {
  return {
    id: o.required('id').asString,
    items: o.required('items').asArrayOfObjects(itemsDeserializer),
  }
}

export function ticketsDeserializer(o) {
  return {
    orderId: o.required('order.id').asString,
  }
}

function pricesDeserializer(o) {
  return {
    priceCategory: o.required('priceCategory').asObject((d) => {
      return {
        id: d.required('id').asString,
        title: d.required('title').asString
      }
    }),
    value: o.required('value').as(moneyMapper),
  }
}

function templateDeserializer(o) {
  return {
    prices: o.required('prices').asArrayOfObjects(pricesDeserializer)
  }
}

function priceTemplateItemsDeserializer(o) {
  return {
    template: o.required('template').asObject(templateDeserializer)
  }
}

export const deserializers = {
  'eventLocalised': (data) => data.eventLocalised.getAvailableList.list.map(e => {
    // Parse dates
    const startTime = new Date(e.startTime);
    const endTime = new Date(e.endTime);
    const currentTime = new Date();

    let features = e.features.map(f => {
      return {
        description: f.description,
        banner: f.banner,
      }
    });

    let status = '';
    if (currentTime >= startTime && currentTime <= endTime) {
      // A stub for the current event
    }

    if (endTime < currentTime) {
      status = 'COMPLETED';
    } else {
      status = e.status;
    }


    return {
      id: e.id,
      startTime,
      endTime,
      hidden: e.hidden,
      status,
      features,
      ...formatDate(startTime),
    }
  }),
  'priceTemplate': (data) => ds(data, o => o.required('priceTemplate.getByEvent').asArrayOfObjects(priceTemplateItemsDeserializer)),
  'ticket': (data) => ds(data, o => o.required('ticket.reserveByCategory').asArrayOfObjects(ticketsDeserializer)),
  'order': (data) => ds(data, o => o.optional('order.getCurrentOnly')?.asObject(o => orderDeserializer(o)))
}

function deserialize(data, key) {
  return deserializers[key](data);
}

export function personDeserializer(d) {
  return {
    name: d.required('name').as((o) => o['ru']),
    surname: d.required('surname').as((o) => o['ru']),
    contacts: d.optional('contacts')?.asArrayOfObjects(contactDeserializer) || [],
  };
}

export function userDeserializer(o) {
  return {
    id: o.required('id').asString,
    person: o.optional('person')?.asObject(personDeserializer),
  };
}

export function contactDeserializer(d) {
  return {
    type: d.required('type').asString,
    value: d.required('value').asString,
  };
}

export default deserialize;
