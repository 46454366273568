export const processMonth = (data) => {
  const monthOrder = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ];
  const uniqueMonths = [];
  data.forEach(ev => {
    const alreadyExist = (item) => (item.month === ev.month) && (item.year === ev.year);
    if (!uniqueMonths.some(alreadyExist)) {
      uniqueMonths.push({ month: ev.month, year: ev.year });
    }
  });
  (uniqueMonths.length > 1) && uniqueMonths.sort((a, b) => {
    if (a.year === b.year) {
      return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
    }
    return a.year - b.year;
  });
  return uniqueMonths.map((item) => item.month);
}

export function groupAndSortSessions(sessions) {
  const groupedSessions = sessions.reduce((acc, session) => {
    const { month, dayOfMonth } = session;

    if (!acc[month]) {
      acc[month] = {
        month,
        days: []
      };
    }

    const days = acc[month].days;

    let targetDay = days.find(day => day.dayOfMonth === dayOfMonth);
    if (!targetDay) {
      targetDay = {
        dayOfMonth,
        sessions: []
      };
      days.push(targetDay);
    }

    targetDay.sessions.push(session);

    return acc;
  }, {});

  const sortedMonths = Object.values(groupedSessions).sort((a, b) => {
    const startTimeA = a.days[0].sessions[0].startTime;
    const startTimeB = b.days[0].sessions[0].startTime;
    return startTimeA - startTimeB;
  });

  sortedMonths.forEach(month => {
    month.days
      .sort((a, b) => a.sessions[0].startTime - b.sessions[0].startTime)
      .forEach(day => {
        day.sessions.sort((a, b) => a.startTime - b.startTime);
      });
  });

  return sortedMonths;
}
