export const AuthState = {
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  AUTHORIZED: 'AUTHORIZED',
  REQUIRE_AUTHORIZATION: 'REQUIRE_AUTHORIZATION',
}

export const RegistrationState = {
  DEFAULT: 'DEFAULT',
  IN_PROCESS: 'IN_PROCESS',
}

export const PaymentsAdapters = {
  SBER: window.parametrize('VUE_APP_SBER_PAYMENT_ADAPTER'),
  SBP: window.parametrize('VUE_APP_SBP_PAYMENT_ADAPTER'),
  BONUSES: window.parametrize('VUE_APP_BONUSES_PAYMENT_ADAPTER'),
  OTHER: window.parametrize('VUE_APP_OTHER_PAYMENT_ADAPTER'),
}
