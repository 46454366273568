import { defineStore } from 'pinia';

export const useTicketsStore = defineStore('ticketsStore', {
  state: () => ({
    loader: false,
    banner: '/1.png',
    discoun: 15,
    summPrice: 0,
    summQuantity: 0,
    tickets: [
      {
        id: 1,
        title: 'Взрослый',
        price: 1000,
        counter: 0,
        description: '',
      },
      {
        id: 2,
        title: 'Детский',
        price: 600,
        counter: 0,
        description:
          'Детский билет может применяться для детей от 3-х до 14 лет. При себе обязательно иметь свидетельство о рождении.',
      },
      {
        id: 3,
        title: 'Льготный',
        price: 600,
        counter: 0,
        description: 'При выборе любого льготного билета, возьмите документ, подтверждающий эту льготу.',
      },
      {
        id: 4,
        title: 'Билет 2',
        price: 600,
        counter: 0,
        description: '',
      },
      {
        id: 5,
        title: 'Билет 3',
        price: 600,
        counter: 0,
        description: '',
      },
      {
        id: 6,
        title: 'Билет 4',
        price: 600,
        counter: 0,
        description: '',
      },
    ],
  }),

  actions: {
    // async getDays(search){
    // 	this.loader = true
    // 	const res = await fetch()
    // 	const data = await res.json()
    // 	this.days = data.results
    // 	this.loader = false
    // }, Примерный код для получения билетов
    inrcCount(id) {
      const idx = this.tickets.findIndex((el) => el.id === id);
      this.tickets[idx].counter += 1;
      this.summQuantity += 1;
      this.summPrice += this.tickets[idx].price;
    },
    decrCount(id) {
      const idx = this.tickets.findIndex((el) => el.id === id);
      if (!this.tickets[idx].counter == 0) {
        this.tickets[idx].counter -= 1;
        this.summQuantity -= 1;
        this.summPrice -= this.tickets[idx].price;
      }
    },
  },
});
