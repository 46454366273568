<template>
  <div class="finish-panel">
    <div class="finish-panel__header">
      {{ fullDateFormatter(orderStore.currentSession.startTime, ['day', 'month', 'hour', 'minute']) }} -
      {{ fullDateFormatter(orderStore.currentSession.endTime, ['hour', 'minute']) }}
    </div>
    <div class="finish-panel__items custom-scrollbar">
      <div class="finish-panel__item" v-for="ticket of orderStore.currentOrder.items">
        <span class="finish-panel__type">{{ ticket.item.priceCategory.title }}</span>
        <div class="finish-panel__qrs-wrapper">
            <QrCode :qr-link="`${ticket.itemQrCode}`"></QrCode>
        </div>
      </div>
    </div>
    <p class="finish-panel__info">Билеты на каток вам отправлены на E-mail и SMS сообщением</p>
  </div>
</template>

<script setup>
import {useUserStore} from '@/store/UserStore';
import {useTicketsStore} from '@/store/TicketsStore';
import {useOrderStore} from "@/store/OrderStore";
import {fullDateFormatter} from "@/utils/formatters";
import {usePricesStore} from "@/store/PricesStore";
import QrCode from "@/components/QrCode.vue";

const ticketsStore = useTicketsStore();
const userStore = useUserStore();
const orderStore = useOrderStore();
const pricesStore = usePricesStore();

const gerQrCodesByCategoryId = (categoryId) => {
  const title = pricesStore.getTitleByCategoryId(categoryId)
  const items = orderStore.currentOrder.items.filter(item => {
    return item.item.priceCategory.title === title
  })
  return items.map(el => el.itemQrCode)
}
</script>

<style lang="stylus">
.finish-panel {
  &__header {
    margin-bottom: 23px;
  }

  &__type {
    margin-bottom: 19px;
  }

  &__header {
    margin-bottom: 12px;
  }

  &__header,
  &__type {
    color: var(--color-dark);
    font-family: var(--font-family);
    font-size: var(--fz-24);
    font-weight: var(--font-400);
  }

  &__items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    max-height: 418px;
    overflow-y: auto;
    margin-bottom: 12px;
  }

  &__info {
    color: #A0A0A0;
    font-size: 10px;
  }

  &__qrs-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  .finish-panel__items {
    gap: 224px;
  }
}
</style>
