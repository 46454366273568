import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {createAuthClient} from '@/auth/client';
import {createTokenRefreshLink} from '@sebbia/auth-client/lib/CredentailsRefreshLink';
import {AuthState} from '@/utils/constants';
import {sanitizePhone} from "@/utils/formatters";

export const useAuthStore = defineStore('auth', () => {
  const authenticated = ref(false);
  const state = ref(AuthState.NOT_AUTHORIZED);

  const authClient = createAuthClient();

  const init = async () => {
    await authClient.init();
    const authorized = authClient.isAuthorized();
    authenticated.value = authorized;
    state.value = authorized ? AuthState.AUTHORIZED : AuthState.NOT_AUTHORIZED;
  };

  init();

  const tokenRefreshLink = createTokenRefreshLink(authClient);

  const getToken = () => localStorage.getItem('credentials') ? JSON.parse(localStorage.getItem('credentials')).idToken : '';

  const isAuthorized = computed(() => authClient.isAuthorized());

  const logout = async () => {
    await authClient.logout();
    authenticated.value = false;
    state.value = AuthState.NOT_AUTHORIZED;
  };

  const login = async (login, password) => {
    const formattedPhone = sanitizePhone(login);
    await authClient.login(formattedPhone, password.trim())
      .then(() => {
        authenticated.value = true;
        state.value = AuthState.AUTHORIZED;
      })
      .catch((e) => {
        console.error(e)
        throw e;
      })
  };

  const requireAuthorization = async () => {
    state.value = AuthState.REQUIRE_AUTHORIZATION;
  };

  return {
    authenticated,
    state,
    tokenRefreshLink,
    // credentials,
    isAuthorized,
    getToken,
    init,
    logout,
    login,
    requireAuthorization,
  };
});
