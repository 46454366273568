import './utils/windowGlobals';
import {createApp, h, provide} from 'vue';
import {createPinia} from 'pinia';
import App from './App.vue';
import VueTheMask from 'vue-the-mask';
import Antd from 'ant-design-vue';
import {DefaultApolloClient} from '@vue/apollo-composable';
import createApolloClient from '@/graphql/client';
import {useAuthStore} from '@/store/AuthStore';
import initPaymentService from "@/services/initPaymentService";

const app = createApp({
	setup() {
		const pinia = createPinia();

		app.use(pinia);
		const authStore = useAuthStore()
		const apolloClient = createApolloClient(authStore)

		provide(DefaultApolloClient, apolloClient)
		initPaymentService();
	},

	render: () => h(App),
})

app.use(Antd).use(VueTheMask).mount('#app');
