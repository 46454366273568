<template>
  <QRCodeVue3
    :width="160"
    :height="160"
    :value="props.qrLink"
    :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
    :image="qrImage"
    :imageOptions="{ hideBackgroundDots: true, imageSize: 0.45, margin: 0 }"
    :dotsOptions="{ type: 'square', color: '#000000' }"
    :cornersSquareOptions="{ type: 'square', color: '#000000' }"
    fileExt="svg"
  />
</template>

<script setup>
import QRCodeVue3 from "qrcode-vue3";
import qrImage from '@/assets/qr-image.svg';

const props = defineProps(['qrLink']);

</script>
