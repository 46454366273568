<template>
  <div class="loader">
    <a-spin :indicator="indicator"/>
  </div>
</template>

<script setup>
import {h, ref, watch} from "vue";
import {LoadingOutlined} from "@ant-design/icons-vue";

const indicator = h(LoadingOutlined, {
  style: {
    fontSize: '60px',
  },
  spin: true,
});
</script>

<style lang="stylus">
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>


