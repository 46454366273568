export const formatDate = (date) => {
	const locale = window.parametrize('VUE_APP_LOCALE');
	const timeZone = window.parametrize('VUE_APP_TIMEZONE');

	let yearFormatter = new Intl.DateTimeFormat(locale, {year: 'numeric', timeZone});
	let monthFormatter = new Intl.DateTimeFormat(locale, {month: 'long', timeZone});
	let dayFormatter = new Intl.DateTimeFormat(locale, {day: 'numeric', timeZone});
	let weekDayFormatter = new Intl.DateTimeFormat(locale, {weekday: 'long', timeZone});
	let weekDayShortFormatter = new Intl.DateTimeFormat(locale, {weekday: 'short', timeZone});
	let hourFormatter = new Intl.DateTimeFormat(locale, {hour: 'numeric', timeZone});
	let minutesFormatter = new Intl.DateTimeFormat(locale, {minute: 'numeric', timeZone});
	let timeFormatter = new Intl.DateTimeFormat(locale, {hour: 'numeric', minute: 'numeric', timeZone});

	return {
		year: yearFormatter.format(date),
		month: uppercaseFirstLetter(monthFormatter.format(date)),
		day: dayFormatter.format(date),
		hour: hourFormatter.format(date),
		minutes: minutesFormatter.format(date),
		time: timeFormatter.format(date),
		weekday: weekDayFormatter.format(date),
		weekdayShort: uppercaseFirstLetter(weekDayShortFormatter.format(date)),
		dayOfMonth: fullDateFormatter(date, ['day', 'month']),
	}
}

export function getStartOfDayISOString() {
	const now = new Date();
	const startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
	return startOfDay.toISOString();
}

export const uppercaseFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const fullDateFormatter = (date, fields, customOptions = {}) => {
	const locale = window.parametrize('VUE_APP_LOCALE');
	const defaultConfig = {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
		weekday: 'long',
		hour: 'numeric',
		minute: 'numeric',
	}
	const options = {}
	fields.forEach(field => {
		options[field] = defaultConfig[field];
	})
	return new Intl.DateTimeFormat(locale, {...options, ...customOptions}).format(date);
}

/**
 * Склонение форм существительных во множественном числе
 * @param  {Integer} amount количество
 * @param  {Array} words Массив слов. Пример: ['товар', 'товара', 'товаров']
 * @return {String} сопряженное слово
 */

export function pluralize(amount, words) {
	amount = Math.abs(amount);
	if (Number.isInteger(amount)) {
		const cases = [2, 0, 1, 1, 1, 2];
		return words[(amount % 100 > 4 && amount % 100 < 20) ? 2 : cases[(amount % 10 < 5) ? amount % 10 : 5]];
	}
	return words[1];
}

export function sanitizePhone(phone) {
	return phone.replace(/\+ ?7/, '7').replace(/[( )_-]/g, '')
}

export function maskPhoneNumber(phoneNumber) {
	const cleaned = String(phoneNumber).replace(/\D/g, '');
	const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

	if (match) {
		return `+ ${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
	}

	return phoneNumber;
}

export function flatUserDTO(userDTO) {
	let phone = null;
	let email = null;
	if (userDTO.person.contacts) {
		phone = userDTO.person.contacts.find(contact => contact.type === 'PHONE');
		email = userDTO.person.contacts.find(contact => contact.type === 'EMAIL');
		phone = phone ? phone.value : '';
		email = email ? email.value : '';
	}
	return {
		id: userDTO.id,
		name: userDTO.person.name,
		surname: userDTO.person.surname,
		phone,
		email,
	};
}

export function splitName(text) {
	const [name, surname] = text.trim().split(/\s+/);
	return {
		name,
		surname
	};
}

export function formattedTimeToResend(ms, short = true) {
	const oneMin = 60000;
	if (ms >= oneMin) {
		return `${Math.floor(ms / oneMin)} ${short ? 'мин.' : 'минут'}`;
	} else if (ms > 0) {
		return `${Math.floor(ms / 1000)} ${short ? 'сек.' : 'секунд'}`;
	} else {
		return `0 ${short ? 'сек.' : 'секунд'}`;
	}
}
