import LocalCredentialStorage from '@sebbia/auth-client/lib/LocalCredentialStorage';
import AuthClient from '@sebbia/auth-client/lib/AuthClient';
import GraphQLClientImpl from '@sebbia/auth-client/lib/GraphQLClientImpl';

export function createAuthClient() {
  const authGraphQlClient = new GraphQLClientImpl(
    window.parametrize('VUE_APP_GRAPHQL_ENDPOINT'),
    20000
  )
  const storage = new LocalCredentialStorage('credentials');
  return new AuthClient(authGraphQlClient, storage);
}

