import {gql} from '@apollo/client/core';

export const RESTORE_PASSWORD = gql`
  mutation restorePassword($phone: String!, $captcha: String) {
    registration {
      restorePassword(phone: $phone, captchaCode: $captcha) {
        nextRestoreAfterMsecs
      }
    }
  }
`;

export const SIMPLE_USER_REGISTRATION = gql`
  mutation registration($phone: String!, $captcha: String) {
    registration {
      simpleUserRegistration(phone: $phone, captchaCode: $captcha) {
        login
      }
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation updateUserProfile($person: PersonUpdateInput) {
    users {
      updateProfile(user: { person: $person }) {
        id
        person {
          name
          surname
          contacts {
            type
            value
          }
        }
      }
    }
  }
`;

export const RESERVE_BY_CATEGORY = gql`
  mutation reserveByCategory($data: TicketByCategoryInput!) {
    ticket {
      reserveByCategory(data: $data) {
        order {
          id
        }
      }
    }
  }
`

export const CANCEL_ORDER = gql`
  mutation cancelOrder($orderId: ID!) {
    order {
      cancel(orderId: $orderId) {
        id
      }
    }
  }
`
