export class Money {
  static precision = 2;
  static validateRx = /^-?\d+(\.\d+)?$/;

  static ZERO = new Money(0);

  constructor(value) {
    this.value = value;
  }

  static parse(s) {
    if (!this.validateRx.test(s)) throw new Error('Invalid money format');

    const values = s.split('.');

    let coins = 0;

    switch (values.length) {
      case 1:
        coins = parseInt(values[0] + '0'.repeat(Money.precision));
        break;
      case 2:
        let right = values[1].slice(0, Money.precision);
        right = right + '0'.repeat(Money.precision - right.length);
        coins = Number.parseInt(values[0] + right);
        break;
      default:
        throw new Error('Invalid money format');
    }

    return new Money(coins);
  }

  plus(other) {
    return new Money(this.value + other.value);
  }

  minus(other) {
    return new Money(this.value - other.value);
  }

  setDiscount(discount) {
    return new Money(this.value * (1 - discount / 100));
  }

  toString() {
    let s = this.value.toString();
    while (s.length < 3) s = '0' + s;
    return s.slice(0, s.length - Money.precision) + '.' + s.slice(s.length - Money.precision, s.length);
  }

  toLocaleString() {
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.00', '') + ' ₽';
  }

  getMain() {
    return parseInt(this.toString())
  }

  getFractional() {
    return this.toString().split('.')[1]
  }
}
