import {defineStore} from 'pinia';
import {computed, ref} from 'vue';
import {useQuery} from '@vue/apollo-composable';
import {GET_AVAILABLE_EVENT_LIST} from '@/graphql/queries';
import deserialize from '@/graphql/deserializers';
import {fullDateFormatter, getStartOfDayISOString, uppercaseFirstLetter} from '@/utils/formatters';
import {groupAndSortSessions, processMonth} from "@/utils/helpers";

export const useEventsStore = defineStore('events', () => {
  const {result, loading, onResult, refetch} = useQuery(GET_AVAILABLE_EVENT_LIST, {
    filter: {
      types: ["REGULAR"],
      statuses: ["COMPLETED", "ACTIVE", "CREATED"],
      eventTime: {start: getStartOfDayISOString()}
    },
    pagination: {
      limit: 1000
    },
  });

  // TODO: A temporary solution, while there is no solution on the backend (for search by project: [TEMP])
  const programmaticallyDisabledEvents = ref([]);
  const disabledEvents = computed(() => programmaticallyDisabledEvents.value); // [TEMP]
  const disableEvent = (id) => programmaticallyDisabledEvents.value.push(id); // [TEMP]

  const months = computed(() => {
    const eventsData = loading.value ? [] : deserialize(result.value, 'eventLocalised');
    return processMonth(eventsData);
  });

  const sessions = computed(() => {
    const eventsData = deserialize(result.value, 'eventLocalised');
    // [TEMP]
    if (disabledEvents.value.length) {
      disabledEvents.value.forEach(disabledEventId => {
        eventsData.map(event => {
          if (event.id === disabledEventId) {
            event.status = 'COMPLETED';
          }
        })
      })
    }

    return groupAndSortSessions(eventsData);
  })

  const events = computed(() => deserialize(result.value, 'eventLocalised') || []);

  const currentMonth = computed(() => {
    const curDate = new Date()
    const curMonth = uppercaseFirstLetter(fullDateFormatter(curDate, ['month']));
    return months.value.find((month) => month === curMonth) || months.value[0] || null;
  })

  return {
    events,
    months,
    sessions,
    currentMonth,
    loading,
    onResult,
    refetch,
    disabledEvents, // [TEMP]
    disableEvent    // [TEMP]
  }
})
