import gql from "graphql-tag";

export const ORDER_FRAGMENT_FIELDS = gql`
    fragment OrderFields on Order {
      id
      items {
        id
        item {
          ... on Ticket {
            priceCategory {
              id
              title
            }
          }
        }
        itemQrCode
      }
    }
`;
