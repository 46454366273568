import {ApolloClient, ApolloLink, createHttpLink, InMemoryCache} from '@apollo/client/core'

export function createApolloClient(authStore) {
  // HTTP connection to the API

  const graphQlLink = createHttpLink({
    // You should use an absolute URL here
    uri: window.parametrize('VUE_APP_GRAPHQL_ENDPOINT'),
  })

  const middlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: {
        authorization: authStore.getToken() ? 'Bearer ' + authStore.getToken() : localStorage.getItem('credentials') ? 'Bearer ' + JSON.parse(localStorage.getItem('credentials')).idToken : '',
      },
    })
    return forward(operation)
  })

  // Cache implementation
  const cache = new InMemoryCache()

  // Create the apollo client
  return new ApolloClient({
    link: ApolloLink.from([middlewareLink, graphQlLink]),
    cache,
  })
}

export default createApolloClient;
