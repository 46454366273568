import {defineStore} from "pinia";
import {provideApolloClient, useQuery} from "@vue/apollo-composable";
import {GET_PRICE_CATEGORY_BY_EVENT} from "@/graphql/queries";
import {computed, ref} from "vue";
import deserialize from "@/graphql/deserializers";
import {useOrderStore} from "@/store/OrderStore";
import {Money} from "@/utils/Money";
import {sberDiscount} from "@/constants";

const processPrices = (prices) => {
  return prices.map(item => {
    return {
      categoryId: item.priceCategory.id,
      amount: 0
    }
  })
}

export const usePricesStore = defineStore('prices', () => {
  const orderStore = useOrderStore();

  const totalPrice = ref(new Money(0));
  const discountTotalPrice = ref(new Money(0));

  const { result, loading, refetch, onResult } = useQuery(
    GET_PRICE_CATEGORY_BY_EVENT,
    {
      eventId: '',
    },
    {
      enable: false,
      prefetch: false
    }
  );

  onResult(() => {
    if (!loading.value) {
      orderStore.setAmount(processPrices(prices.value));
    }
  })

  const prices = computed(() => {
    let deserealizedData = [];
    if (deserialize(result.value, 'priceTemplate').length > 0) {
      deserealizedData = deserialize(result.value, 'priceTemplate')[0].template.prices || [];
    }

    return deserealizedData
  });

  const getTotalPrice = computed(() => {
    return totalPrice.value
  })

  const getDiscountTotalPrice = computed(() => {
    return discountTotalPrice.value
  })

  const getDiscountAmount = computed(() => {
    return totalPrice.value.minus(discountTotalPrice.value)
  })

  const getTitleByCategoryId = computed(() => (categoryId) => {
    return prices.value.find(item => item.priceCategory.id === categoryId).priceCategory.title
  })

  const getPriceByCategoryId = computed(() => (categoryId) => {
    const val = prices.value.find(item => item.priceCategory.id === categoryId);
    return val.value
  })

  function fetchPlacePrices(currentSessionId) {
    refetch({ eventId: currentSessionId });
  }

  function increaseTotalPrices(value) {
    totalPrice.value = (totalPrice.value).plus(value)
    discountTotalPrice.value = totalPrice.value.setDiscount(sberDiscount)
  }

  function decreaseTotalPrices(value) {
    totalPrice.value = (totalPrice.value).minus(value)
    discountTotalPrice.value = totalPrice.value.setDiscount(sberDiscount)
  }

  function pricesReset() {
    totalPrice.value = new Money(0)
    discountTotalPrice.value = new Money(0)
  }

  return {
    prices,
    loading,
    getTotalPrice,
    getDiscountTotalPrice,
    getDiscountAmount,
    getTitleByCategoryId,
    getPriceByCategoryId,
    fetchPlacePrices,
    increaseTotalPrices,
    decreaseTotalPrices,
    pricesReset,
  }
})
